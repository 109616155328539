/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import StickyBox from "react-sticky-box";
import { Sidebar } from "../sidebar";
import { Link } from "react-router-dom";
import AuthApi from "../../../../Apis/AuthApi";
import { toast } from "react-toastify";
import TokenManager from "../../../../Apis/TokenManager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const FreelancerDeleteAccount = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [reason, setReason] = useState("");

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleDeleteAccount = async () => {
    if (password === "") {
      toast.error("password is required!");
      return;
    }
    if (reason === "") {
      toast.error("please explain reason!");
      return;
    }
    setLoading(true);
    try {
      const response = await AuthApi.deleteAccount();
      console.log("responsee", response);
      if (response.success) {
        toast.success("your account is deleted successfully");
        TokenManager.clear();
        window.location.href = "/";
      } else {
        toast.error("could not delete account");
      }
      setLoading(false);
    } catch (error) {
      toast.error("could not delete account");
    }
  };

  useEffect(() => {
    document.body.className = "dashboard-page";
    return () => {
      document.body.className = "";
    };
  });
  return (
    <>
      {/* Page Content */}
      <div className="content content-page">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4 theiaStickySidebar">
              <StickyBox offsetTop={20} offsetBottom={20}>
                <Sidebar />
              </StickyBox>
            </div>
            <div className="col-xl-9 col-lg-8">
              <div className="dashboard-sec payout-section freelancer-statements">
                <div className="page-title portfolio-title">
                  <h3 className="mb-0">{t("deleteAccount.title")}</h3>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-block">
                      <label className="focus-label">
                        {t("deleteAccount.explanation")}{" "}
                        <span className="label-star"> *</span>
                      </label>
                      <textarea
                        className="form-control"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        rows={5}
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="input-block">
                      <label className="focus-label">
                        {t("deleteAccount.password")}{" "}
                        <span className="label-star"> *</span>
                      </label>
                      <div className="position-relative">
                        <input
                          type={isPasswordVisible ? "text" : "password"}
                          className="form-control floating pass-input1"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <div
                          className="password-icon"
                          onClick={togglePasswordVisibility}
                        >
                          <span
                            className={`fas toggle-password1 ${
                              isPasswordVisible ? "fa-eye" : "fa-eye-slash"
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="d-flex doc-btn">
                      <Link to="#" className="btn btn-gray">
                        {t("deleteAccount.cancel")}
                      </Link>
                      <Link
                        to="#"
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#delete-acc"
                      >
                        {t("deleteAccount.deleteAccount")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Content */}
      {/* The Modal */}
      <div className="modal fade custom-modal" id="delete-acc">
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-body del-modal">
              <form>
                <div className="text-center pt-0 mb-5">
                  <i className="fas fa-exclamation-triangle fa-3x" />
                  <h3>{t("deleteAccount.confirmationTitle")}</h3>
                </div>
                <div className="submit-section text-center">
                  <button
                    data-bs-dismiss="modal"
                    className="btn btn-primary black-btn click-btn"
                  >
                    {t("deleteAccount.cancel")}
                  </button>
                  &nbsp;
                  <button
                    type="button"
                    onClick={handleDeleteAccount}
                    className="btn btn-primary click-btn"
                  >
                    {loading ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      <>
                        {t("deleteAccount.submit")}{" "}
                        <i className="feather-arrow-right ms-2" />
                      </>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /The Modal */}
    </>
  );
};
export default FreelancerDeleteAccount;
