/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import SimpleReactLightbox from "simple-react-lightbox";
import {
  about_details,
  home_icon,
  logo_details,
  profile_details,
  Tab_icon_09,
  Tab_icon_10,
  Tab_icon_11,
  logo_icon_01,
  Flags_nl,
  find,
  requests,
  posts,
  Avatar_1,
} from "../imagepath";
import { useHistory, useParams } from "react-router-dom";
import { useCategory } from "../Category";
import AuthApi from "../../../Apis/AuthApi";
import TechRequestsApi from "../../../Apis/TechRequestsApi";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../LoadingSpinner";
import SubCategoryApi from "../../../Apis/SubCategoryApi";
import getDateTime from "../../../getDateTime";
import StickyBox from "react-sticky-box";
import { ProfileSidebar } from "../forfreelancer/sidebar/profilesidebar";
import { useTranslation } from "react-i18next";

const CompanyGallery = () => {
  const { t } = useTranslation();
  const history = useHistory();
  let params = useParams();
  const { getCategoryById } = useCategory();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [request, setRequest] = useState();
  const [subCategories, setSubCategories] = useState({});

  const fetchSubCategory = async (id) => {
    if (!subCategories[id]) {
      const response = await SubCategoryApi.getSubCategoryById(id);
      if (response.success) {
        setSubCategories((prev) => ({
          ...prev,
          [id]: response.data.name,
        }));
      }
    }
  };
  const getUserTechRequests = async (id) => {
    const response = await TechRequestsApi.getTechRequestByUserID(id);
    if (response.success) {
      setRequest(response.data);
    } else {
      toast.error("something went wrong");
    }
  };

  const getUserById = async (id) => {
    const response = await AuthApi.getUser(id);
    if (response.success) {
      setUser(response.data);
      await getUserTechRequests(id);
      setLoading(false);
    } else {
      toast.error("something went wrong");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (request) {
      request.forEach((item) => {
        if (item.subCategoryId) {
          fetchSubCategory(item.subCategoryId);
        }
      });
    }
  }, [request]);

  useEffect(() => {
    const fetchData = async () => {
      const isValidUUID = (id) => {
        const uuidRegex =
          /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        return uuidRegex.test(id);
      };
      if (params.id && isValidUUID(params.id)) {
        getUserById(params.id);
      } else {
        history.push("/404");
      }
    };
    fetchData();
  }, [params?.id]);

  if (!params.id) {
    history.pushState("/404");
  }
  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {/* Breadcrumb */}
      <div className="bread-crumb-bar">
        <div className="container">
          <div className="row align-items-center inner-banner">
            <div className="col-md-12 col-12 text-center">
              <div className="breadcrumb-list">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">
                        <img src={home_icon} alt="" /> Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    {t('CompanyDetails.companyProfile')}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      {/* Profile Banner */}
      <section className="profile-baner">
        <div className="container">
          <div className="row">
            <div className="col-auto">  
              <div className="profile-img">
                <img src={user?.image || Avatar_1} alt="" width={150} />
              </div>
            </div>
            <div className="col">
              <div className="profile-main">
                <h2>
                  {user?.businessName}
                  <i className="fas fa-check-circle" />
                </h2>
                {/* <p>Since July 2024</p> */}
                <div className="about-list">
                  <ul>
                    <li>
                      <img src={Flags_nl} alt="Lang" /> {user?.address?.country}
                    </li>
                    <li>
                      <i className="fas fa-map-marker-alt" />{" "}
                      {user?.address?.city}
                    </li>
                    <li>
                      <i className="far fa-clock" />{t('CompanyDetails.postedOneHourAgo')}
                    </li>
                  </ul>
                </div>
                {/* <div className="rating">
                                    <i className="fas fa-star filled" />
                                    <i className="fas fa-star filled" />
                                    <i className="fas fa-star filled" />
                                    <i className="fas fa-star " />
                                    <i className="fas fa-star" />
                                    <span className="average-rating ml-2"> 4.6 (25)</span>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Profile Banner */}
      {/* Page Content */}
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="pro-view">
                {/* Tab Detail */}
                <nav className="provider-tabs mb-4 abouts-view">
                  <ul className="nav nav-tabs nav-tabs-solid nav-justified">
                    <li className="nav-item">
                      <Link
                        className="nav-link "
                        to={`/company-details/${user?.id}`}
                      >
                        <img src={find} alt="User Image" width={30} />
                        <p className="bg-red">{t('CompanyDetails.about')} </p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to={`/company-posts/${user?.id}`}
                      >
                        <img src={posts} alt="User Image" width={30} />
                        <p>{t('CompanyDetails.posts')}</p>
                      </Link>
                    </li>
                    <li className="nav-item ">
                      <Link
                        className="nav-link active-about"
                        to={`/company-requests/${user?.id}`}
                      >
                        <img src={requests} alt="User Image" width={30} />
                        <p>{t('CompanyDetails.requests')}</p>
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                                            <Link className="nav-link" to="/company-review">
                                                <img src={Tab_icon_11} alt="User Image" />
                                                <p>Reviews</p>
                                            </Link>
                                        </li> */}
                  </ul>
                </nav>
                {/* /Tab Detail */}
                {/* About Tab Content */}
                <div className="pro-post widget-box company-post">
                  <h3 className="pro-title">{t('CompanyDetails.requests')}</h3>
                  <div className="pro-content company-gallery">
                    {request?.map((item, ind) => {
                      return (
                        <>
                          <div className="projects-card flex-fill project-company">
                            <div className="card-body">
                              <div className="projects-details align-items-center">
                                <div className="project-info">
                                  <span>{getCategoryById(item.categoryId)}</span> 
                                  <h2>{item?.title}</h2>
                                  <div className="customer-info">
                                    <ul className="list-details">
                                      <li>
                                        <div className="slot">
                                          <p>{t('CompanyPosts.tags')}</p>
                                          <h5> {subCategories[item.subCategoryId] ||
                                          "Loading..."}</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="slot">
                                          <p>{t('CompanyPosts.createdAt')}</p>
                                          <h5>{getDateTime.getDate(item?.createdDate)} | {getDateTime.getTime(item?.createdDate)}</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="slot">
                                          <p>{t('CompanyPosts.status')}</p>
                                          <a
                                            href="#"
                                            style={{ color: "#71d3a9" }}
                                          >
                                            Open
                                          </a>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="project-hire-info">
                                  <div className="content-divider" />
                                  <div className="projects-amount">
                                    <Link to="#" className="hired-detail">
                                      <span>{item?.comments?.length}</span>  {t('CompanyPosts.proposals')}
                                    </Link>
                                  </div>
                                  <div className="content-divider" />
                                  <div className="projects-action text-center">
                                    <Link
                                      to={`/request-details/${item?.id}`}
                                      className="projects-btn"
                                    >
                                     {t('CompanyPosts.sendProposal')}
                                    </Link>
                                    {/* <Link
                              to="/view-project-detail"
                              className="projects-btn"
                            >
                              Edit Jobs
                            </Link> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}

                    {/* Pagination */}
                    <div className="row">
                      <div className="col-md-12">
                        <ul className="paginations list-pagination">
                          <li className="page-item">
                            <Link to="#">{"<"}</Link>
                          </li>
                          <li className="page-item">
                            <Link to="#" className="active">
                              1
                            </Link>
                          </li>
                          <li className="page-item">
                            <Link to="#">{">"}</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /About Tab Content */}
              </div>
            </div>
            {/* profile Sidebar */}
            <div className="col-lg-4 col-md-12 sidebar-right theiaStickySidebar company-profile">
              <StickyBox offsetTop={20} offsetBottom={20}>
                <ProfileSidebar user={user}/>
              </StickyBox>
            </div>
            {/* /Profile Sidebar */}
          </div>
        </div>
      </div>
      {/* /Page Content */}

      {/* /Main Wrapper */}
    </>
  );
};
export default CompanyGallery;
