/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import StickyBox from "react-sticky-box";
import { Sidebar } from "../sidebar";

import { Link } from "react-router-dom";
import TokenManager from "../../../../Apis/TokenManager";
import AuthApi from "../../../../Apis/AuthApi";
import { toast } from "react-toastify";
import { Avatar_1 } from "../../imagepath";
import LoadingSpinner from "../../../../LoadingSpinner";
import { useTranslation } from "react-i18next";

const FreelancerSettings = () => {
  const { t } = useTranslation();
  const claims = TokenManager.getClaims();
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updatedDetails, setUpdatedDetails] = useState({
    businessName: "",
    email: "",
    kvk: "",
    address: {
      street: "",
      city: "",
      state: "",
      postCode: "",
      country: "",
    },
    phoneNumber: "",
    description: "",
  });

  const fetchImageAsBlob = async (imageUrl) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return blob;
  };

  const convertBlobToFile = (blob, filename) => {
    return new File([blob], filename, { type: blob.type });
  };
  const createFormDataFromUrls = async (url) => {
      const blob = await fetchImageAsBlob(url);
      const file = convertBlobToFile(blob, `Avatar.png`);
    return file
    }


  const getUserDetails = async () => {
    setLoading(true)
    const response = await AuthApi.getUser(claims?.sub);
    if (response.success) {
      const userData = response.data;
    const imageFile=  await createFormDataFromUrls(userData.image);
      setUpdatedDetails({
        businessName: userData.businessName || "",
        email: userData.email || "",
        kvk: userData.kvk || "",
        address: {
          street: userData.address?.street || "",
          city: userData.address?.city || "",
          state: userData.address?.state || "",
          postCode: userData.address?.postCode || "",
          country: userData.address?.country || "",
        },
        phoneNumber: userData.phoneNumber || "",
        description: userData.description || "",
        image:imageFile
      });
      setImage(userData.image);
    } else {
      toast.error("Something went wrong");
    }
    setLoading(false)
  };

  const handleDetailChange = (name, value) => {
    if (name.startsWith("address.")) {
      const addressField = name.split(".")[1];
      setUpdatedDetails((prevDetails) => ({
        ...prevDetails,
        address: {
          ...prevDetails.address,
          [addressField]: value,
        },
      }));
    } else {
      setUpdatedDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
      setUpdatedDetails((prevDetails) => ({
        ...prevDetails,
        image: file,
      }));
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    const formData = new FormData();
    formData.append("BusinessName", updatedDetails.businessName);
    formData.append("Email", updatedDetails.email);
    formData.append("Kvk", updatedDetails.kvk);
    formData.append("PhoneNumber", updatedDetails.phoneNumber);
    formData.append("Description", updatedDetails.description);
    formData.append("Address", updatedDetails.address);
    formData.append("Image", updatedDetails?.image, ".png");

    const response = await AuthApi.updateUser(formData);
    if (response.success) {
      toast.success("Profile updated successfully");
      getUserDetails();
    } else {
      toast.error("Failed to update profile");
    }
    setLoading(false)
  };

  useEffect(() => {
    document.body.className = "dashboard-page";
    return () => {
      document.body.className = "";
    };
  }, []);

  useEffect(() => {
    getUserDetails();
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }


  return (
    <>
      {/* Page Content */}
      <div className="content content-page">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4 theiaStickySidebar">
              <StickyBox offsetTop={20} offsetBottom={20}>
                <Sidebar />
              </StickyBox>
            </div>
            <div className="col-xl-9 col-lg-8">
              <div className="pro-pos">
                <div className="setting-content">
                  <form onSubmit={handleSubmit}>
                    <div className="card">
                      <div className="pro-head">
                        <h3>{t('profileSettings.title')}</h3>
                      </div>
                      <div className="pro-body">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="form-row pro-pad pt-0 ps-0">
                              <div className="input-block col-md-6 pro-pic">
                                <label className="form-label">{t('profileSettings.profilePicture')}</label>
                                <div className="d-flex align-items-center">
                                  <div className="upload-images freelancer-pic-box">
                                    <img src={image || Avatar_1} alt="" id="blah" style={{width:"100%"}}/>  
                                  </div>
                                  <div className="ms-3 freelancer-pic-upload">
                                    <label className="file-upload image-upbtn">
                                    {t('profileSettings.uploadImage')}
                                      <input
                                        type="file"
                                        id="imgInp"
                                        onChange={handleImageChange}
                                      />
                                    </label>
                                    <p>{t('profileSettings.maxImageSize')}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="mb-3">
                              <label className="form-label">{t('profileSettings.businessName')}</label>
                              <input
                                type="text"
                                className="form-control"
                                value={updatedDetails.businessName}
                                onChange={(e) =>
                                  handleDetailChange("businessName", e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="mb-3">
                              <label className="form-label">KVK</label>
                              <input
                                type="text"
                                className="form-control"
                                value={updatedDetails.kvk}
                                onChange={(e) => handleDetailChange("kvk", e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="mb-3">
                              <label className="form-label">{t('profileSettings.phoneNumber')}</label>
                              <input
                                type="text"
                                className="form-control"
                                value={updatedDetails.phoneNumber}
                                onChange={(e) =>
                                  handleDetailChange("phoneNumber", e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="mb-3">
                              <label className="form-label">{t('profileSettings.emailAddress')}</label>
                              <input
                                type="text"
                                className="form-control"
                                value={updatedDetails.email}
                                onChange={(e) => handleDetailChange("email", e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="pro-head">
                        <h4 className="pro-titles mb-0">{t('profileSettings.about')}</h4>
                      </div>
                      <div className="pro-body">
                        <div className="row">
                          <div className="input-block col-md-12">
                            <label className="form-label">{t('profileSettings.description')}</label>
                            <textarea
                              className="form-control"
                              rows={5}
                              value={updatedDetails.description}
                              onChange={(e) =>
                                handleDetailChange("description", e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-lg-12 w-100">
                        <div className="card flex-fill mb-3">
                          <div className="pro-head">
                            <h4 className="pro-titles mb-0">{t('profileSettings.address')}</h4>
                          </div>
                          <div className="pro-body">
                            <div className="form-row align-items-center skill-cont">
                              <div className="input-block col-lg-4">
                                <label className="form-label">{t('profileSettings.street')}</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={updatedDetails.address.street}
                                  onChange={(e) =>
                                    handleDetailChange("address.street", e.target.value)
                                  }
                                />
                              </div>
                              <div className="input-block col-lg-4">
                                <label className="form-label">{t('profileSettings.number')}</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={updatedDetails.address.state}
                                  onChange={(e) =>
                                    handleDetailChange("address.state", e.target.value)
                                  }
                                />
                              </div>
                              <div className="input-block col-lg-4">
                                <label className="form-label">{t('profileSettings.zipcode')}</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={updatedDetails.address.postCode}
                                  onChange={(e) =>
                                    handleDetailChange("address.postCode", e.target.value)
                                  }
                                />
                              </div>
                              <div className="input-block col-lg-4">
                                <label className="form-label">{t('profileSettings.city')}</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={updatedDetails.address.city}
                                  onChange={(e) =>
                                    handleDetailChange("address.city", e.target.value)
                                  }
                                />
                              </div>
                              <div className="input-block col-lg-4">
                                <label className="form-label">{t('profileSettings.country')}</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={updatedDetails.address.country}
                                  onChange={(e) =>
                                    handleDetailChange("address.country", e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-lg-12">
                        <div className="card flex-fill mb-3">
                          <div className="pro-head">
                            <h4 className="pro-titles mb-0">Business Website</h4>
                          </div>
                          <div className="pro-body">
                            <div className="form-row align-items-center skill-cont">
                              <div className="input-block col-lg-12">
                                <label className="form-label">Website Url</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={updatedDetails.websiteUrl}
                                  onChange={(e) =>
                                    handleDetailChange("websiteUrl", e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>

                    <div className="card text-end border-0">
                      <div className="pro-body">
                        <button
                          className="btn btn-secondary click-btn btn-plan"
                          type="button"
                          onClick={() => window.history.back()}
                        >
                          {t('profileSettings.cancel')}
                        </button>
                        <button
                          className="btn btn-primary click-btn btn-plan"
                          type="submit"
                          onClick={(e)=>handleSubmit(e)}
                        >
                          {t('profileSettings.update')}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Content */}
    </>
  );
};

export default FreelancerSettings;
