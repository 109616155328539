/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// Import Slick Slider
// Import Images
import {
  About_img,
  About_01,
  Review_01,
  Review_02,
  Review_03,
  home_icon,
  Logo,
  About_1,
  about_us,
  about_us_1,
  great_1,
  great_2,
  great_3,
  great_4,
  abt_3,
  checks,
  count_icon_01,
  count_icon_02,
  count_icon_03,
  count_icon_04,
  job1,
  Great_icon_01,
  Great_icon_02,
  Great_icon_03,
  Great_icon_04,
} from "../imagepath";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import EmployerBreadcrumb from "../foremployers/common/employerBreadcrumb";
import AOS from "aos";
import "aos/dist/aos.css";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";

const Aboutus = () => {
  const { t } = useTranslation();
  const options = {
    items: 5,
    margin: 30,
    dots: false,
    nav: true,
    smartSpeed: 2000,
    navText: [
      '<i class="fas fa-chevron-left"></i>',
      '<i class="fas fa-chevron-right"></i>',
    ],
    loop: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      991: {
        items: 3,
      },
      1170: {
        items: 3,
      },
    },
  };

  useEffect(() => {
    AOS.init({
      duration: 800,
      // easing: 'ease-in-out',
      offset: 200,
      once: true,
    });
  }, []);
  return (
    <>
      {/* Breadcrumb */}
      <EmployerBreadcrumb title={t("aboutus.aboutUs")} subtitle={t("aboutus.aboutUs")} />

      {/* /Breadcrumb */}
      {/* About */}
      <section className="section about">
        <div className="container">
          <div className="row justify-content-center">
            <div
              className="col-lg-12 d-flex align-items-center aos"
              data-aos="fade-up"
            >
              <div className="about-content">
                <h2>{t("aboutus.aboutTechForShare")}</h2>
                <p>{t("aboutus.aboutTechForShareText1")}</p>
                <p>{t("aboutus.aboutTechForShareText2")}</p>
              </div>
            </div>
            <div className="col-lg-6 ">
              <div className="about-content-img aos" data-aos="zoom-in">
                <img
                  src={about_us}
                  className="img-fluid"
                  alt="img"
                  width={500}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-content-img aos" data-aos="zoom-in">
                <img
                  src={about_us_1}
                  className="img-fluid"
                  alt="img"
                  width={500}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /About */}
      {/* Counter */}
      <section className="section projects">
        <div className="container">
          <div className="row row-gap">
            <div className="col-md-12 col-sm-12 col-12 mx-auto text-center">
              <div className="section-header aos  " data-aos="fade-up">
                <h2 className="header-title">{t("aboutus.whatsGreatAbout")}</h2>
                <p>{t("aboutus.featuresTechForShare")}</p>
              </div>
            </div>
            {/* Feature Item */}
            <div
              className="col-xl-3 col-md-6 aos d-flex  "
              data-aos="zoom-in"
              data-aos-duration={1000}
            >
              <div className="feature-items d-flex align-items-center justify-content-center flex-column">
                <div className="feature-icon">
                  <img src={Great_icon_01} className="img-fluid" alt="Img" />
                </div>
                <div className="feature-content course-count text-center">
                  <h3>{t("aboutus.browseRepairShops")}</h3>
                  <p>{t("aboutus.findTrustedProfessionals")}</p>
                </div>
              </div>
            </div>
            {/* /Feature Item */}
            {/* Feature Item */}
            <div
              className="col-xl-3 col-md-6 aos d-flex  "
              data-aos="zoom-in"
              data-aos-duration={1500}
            >
              <div className="feature-items d-flex align-items-center justify-content-center flex-column">
                <div className="feature-icon">
                  <img src={Great_icon_02} className="img-fluid" alt="Img" />
                </div>
                <div className="feature-content course-count text-center">
                <h3>{t("aboutus.fastBids")}</h3>
                <p>{t("aboutus.fastBidsText")}</p>
                </div>
              </div>
            </div>
            {/* /Feature Item */}
            {/* Feature Item */}
            <div
              className="col-xl-3 col-md-6 aos d-flex  "
              data-aos="zoom-in"
              data-aos-duration={2000}
            >
              <div className="feature-items d-flex align-items-center justify-content-center flex-column">
                <div className="feature-icon">
                  <img src={Great_icon_03} className="img-fluid" alt="Img" />
                </div>
                <div className="feature-content course-count text-center">
                  <h3>{t("aboutus.requestParts")}</h3>
                  <p>{t("aboutus.requestPartsText")}</p>
                </div>
              </div>
            </div>
            {/* /Feature Item */}
            {/* Feature Item */}
            <div
              className="col-xl-3 col-md-6 aos d-flex  "
              data-aos="zoom-in"
              data-aos-duration={2500}
            >
              <div className="feature-items d-flex align-items-center justify-content-center flex-column">
                <div className="feature-icon">
                  <img src={Great_icon_04} className="img-fluid" alt="Img" />
                </div>
                <div className="feature-content course-count text-center">
                  <h3>{t("aboutus.browseForParts")}</h3>
                  <p>{t("aboutus.browseForPartsText")}</p>
                </div>
              </div>
            </div>
            {/* /Feature Item */}
          </div>
        </div>
      </section>

      {/* Review */}
      <section className="section review">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="aos aos-init " data-aos="fade-up">
                <div className="demand-professional">
                  <h2>{t("aboutus.connectingRepairShopsTitle")}</h2>
                  <p>{t("aboutus.connectingRepairShopsPara1")}</p>
                </div>
                <div className="demand-post-job align-items-start">
                  <div className="demand-post-img">
                    <img src={checks} alt="Img" className="img-fluid" />
                  </div>
                  <div className="demand-content">
                    <p>{t("aboutus.connectingRepairShopsPara2")}</p>
                  </div>
                </div>
                <div className="demand-post-job align-items-start">
                  <div className="demand-post-img">
                    <img src={checks} alt="Img" className="img-fluid" />
                  </div>
                  <div className="demand-content">
                    <p>{t("aboutus.connectingRepairShopsPara3")}</p>
                  </div>
                </div>
                <div className="demand-post-job align-items-start">
                  <div className="demand-post-img">
                    <img src={checks} alt="Img" className="img-fluid" />
                  </div>
                  <div className="demand-content">
                    <p>{t("aboutus.connectingRepairShopsPara4")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="work-set-image">
                <div
                  className="recent-pro-img aos"
                  data-aos="zoom-in"
                  data-aos-duration={1000}
                >
                  <img src={abt_3} alt="Img" className="img-fluid " />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* / Review */}
      {/* Agency */}

      <section className="section job-register">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="register-job-blk">
                <div className="job-content-blk aos" data-aos="fade-up">
                  <h2>{t("aboutus.findBestSolutionTitle")}</h2>
                  <p>{t("aboutus.findBestSolutionText")}</p>
                  <Link to="/register" className="btn all-btn">
                    {t("aboutus.joinNow")}
                  </Link>
                </div>
                <div
                  className="see-all mt-0 aos opportunity"
                  data-aos="zoom-in"
                >
                  <img src={job1} alt="img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Aboutus;
