/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Avatar_1 } from "../../imagepath";

import StickyBox from "react-sticky-box";
import EmployerBreadcrumb from "../../foremployers/common/employerBreadcrumb";
import TextEditor from "../../foremployers/dashboard/texteditor";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useParams } from "react-router-dom";
import TechOffers from "../../../../Apis/TechOffersApi";
import { useCategory } from "../../Category";
import AuthApi from "../../../../Apis/AuthApi";
import getDateTime from "../../../../getDateTime";
import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";
import TokenManager from "../../../../Apis/TokenManager";
import LoadingSpinner from "../../../../LoadingSpinner";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import ConversationApi from "../../../../Apis/ConversationApi";
import { useTranslation } from "react-i18next";

const CompanyProfile = () => {

  const history = useHistory();
  let params = useParams();
  const claims = TokenManager.getClaims();
  const { categories, getCategoryById } = useCategory();
  const [replyMessage, setReplyMessage] = useState("");
  const [contactMessage, setContactMessage] = useState("");
  const [userToReply, setUserToReply] = useState();
  const [post, setPost] = useState();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(new Date());
  const [comment, setComment] = useState();
  const [userCommentCache, setUserCommentCache] = useState({});
  const { t } = useTranslation();

  const getPostById = async (id) => {
    setLoading(true);
    const response = await TechOffers.getTechOffer(id);
    if (response.success) {
      setPost(response.data);
      await fetchUsers(response.data?.comments);
      const requestUser = await getUserById(response.data?.userId);
      setUser(requestUser);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const fetchUsers = async (requests) => {
    const uniqueUserIds = [...new Set(requests.map((post) => post.userId))];
    const userPromises = uniqueUserIds.map((id) => getUserById(id));
    const users = await Promise.all(userPromises);

    const userCacheData = users.reduce((acc, user) => {
      if (user) {
        acc[user.id] = user;
      }
      return acc;
    }, {});
    setUserCommentCache(userCacheData);
  };

  const getUserById = async (id) => {
    if (userCommentCache[id]) {
      return userCommentCache[id];
    }

    const response = await AuthApi.getUser(id);
    if (response.success) {
      return response.data;
    } else {
      toast.error("something went wrong");
      return null;
    }
  };

  const handleAddComment = async () => {
    if (!comment || comment === "") {
      toast.error("you can not send empty message");
      return;
    }
    const payload = {
      techOfferId: post.id,
      text: comment,
    };
    const response = await TechOffers.addTechOfferComment(payload);
    if (response.success) {
      getPostById(params.id);
      setComment("");
      toast.success("your comment is added successfullt");
    }
  };
  const handleDeleteComment = async (id) => {
    const payload = {
      id: id,
      techOfferId: post.id,
    };
    const response = await TechOffers.removeTechOfferComment(payload);
    if (response.success) {
      getPostById(params.id);
      setComment("");
      toast.success("your comment is deleted successfully");
    } else {
      toast.error("Could not delete comment");
    }
  };
  
  const handleSendReply = async (e) => {
    e.preventDefault();
    if (replyMessage === "") {
      toast.error("Please write a reply message");
    }
    setLoading(true)
    const payload = {
      conversationId: null,
      receiverId: userToReply || user.id,
      text: `Replyed on Offer:${params.id}+ ${replyMessage}`,
    };
    const response = await ConversationApi.SendMessage(payload);
    if (response.success) {
      toast.success("message sent successfully");
     window.location.href="/chats"
    }
    setLoading(false)
  };

  const handleContactMe= async (e) => {
    e.preventDefault();
    if (contactMessage === "") {
      toast.error("Please write a  message");
    }
    setLoading(true)
    const payload = {
      conversationId: null,
      receiverId: userToReply || user.id,
      text:contactMessage,
    };
    const response = await ConversationApi.SendMessage(payload);
    if (response.success) {
      toast.success("message sent successfully");
     window.location.href="/chats"
    }
    setLoading(false)
  };


  useEffect(() => {
    const fetchData = async () => {
      const isValidUUID = (id) => {
        const uuidRegex =
          /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        return uuidRegex.test(id);
      };
      if (params.id && isValidUUID(params.id)) {
        getPostById(params.id);
      } else {
        history.push("/404");
      }
    };
    fetchData();
  }, [params?.id]);

  if (!params.id) {
    history.pushState("/404");
  }
  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {/* Breadcrumb */}
      <EmployerBreadcrumb title={t('postDetails.partDetails')} subtitle={t('postDetails.projectDetails')} />
      {/* /Breadcrumb */}

      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="company-detail-block pt-0">
                <div className="company-detail">
                  <div className="company-detail-image">
                    <img
                      src={user?.image || Avatar_1}
                      className="img-fluid"
                      alt="logo"
                      style={{ width: "150px", height: "150px" }}
                    />
                  </div>
                  <div className="company-title">
                    <p>{user?.businessName}</p>
                    <h4>{post?.title}</h4>
                  </div>
                </div>
                <div className="company-address">
                  <ul>
                    <li>
                      <i className="feather-map-pin" />
                      {user?.address?.city},{user?.address?.country}
                    </li>
                    <li>
                      <i className="feather-calendar" />
                      {getDateTime.getDate(post?.createdDate)}
                    </li>
                    {/* <li>
                      <i className="feather-eye" />
                      902 Views
                    </li> */}
                    <li>
                      <i className="feather-edit-2" />
                      {post?.comments?.length} {t('proposal')}
                    </li>
                  </ul>
                </div>
                <div className="tags">
                  <Link to="#">
                    <span className="badge badge-pill badge-design">
                      {getCategoryById(post?.categoryId)}
                    </span>
                  </Link>
                </div>
                {/* <div className="project-proposal-detail">
                  <ul>
                    <li>
                      <div className="proposal-detail-img">
                        <img src={computer_line} alt="icons" />
                      </div>
                      <div className="proposal-detail text-capitalize">
                        <span className=" d-block">Freelancer Type</span>
                        <p className="mb-0">Full Time</p>
                      </div>
                    </li>
                    <li>
                      <div className="proposal-detail-img">
                        <img src={time_line} alt="icons" />
                      </div>
                      <div className="proposal-detail text-capitalize">
                        <span className=" d-block">Project Type</span>
                        <p className="mb-0">Hourly</p>
                      </div>
                    </li>
                    <li>
                      <div className="proposal-detail-img">
                        <img src={time_line} alt="icons" />
                      </div>
                      <div className="proposal-detail text-capitalize">
                        <span className=" d-block">Project Duration</span>
                        <p className="mb-0">10-15 Hours</p>
                      </div>
                    </li>
                    <li>
                      <div className="proposal-detail-img">
                        <img src={user_heart_line} alt="icons" />
                      </div>
                      <div className="proposal-detail text-capitalize">
                        <span className=" d-block">Experience</span>
                        <p className="mb-0">Basic</p>
                      </div>
                    </li>
                    <li>
                      <div className="proposal-detail-img">
                        <img src={translate_2} alt="icons" />
                      </div>
                      <div className="proposal-detail text-capitalize">
                        <span className=" d-block">Languages</span>
                        <p className="mb-0">English, Arabic</p>
                      </div>
                    </li>
                    <li>
                      <div className="proposal-detail-img">
                        <img src={translate} alt="icons" />
                      </div>
                      <div className="proposal-detail text-capitalize">
                        <span className=" d-block">Language Fluency</span>
                        <p className="mb-0">Conversational</p>
                      </div>
                    </li>
                  </ul>
                </div> */}
              </div>
              <div className="company-detail-block company-description">
                <h4 className="company-detail-title">{t('postDetails.description')}</h4>
                <p
                  style={{
                    maxWidth: "825",
                    overflowWrap: "break-word",
                    wordBreak: "break-word",
                  }}
                >
                  {post?.description}
                </p>
              </div>
              {/* <div className="company-detail-block company-description">
                <h4 className="company-detail-title">Skills Required</h4>
                <div className="tags">
                  <Link to="#">
                    <span className="badge badge-pill badge-design">
                      After Effects
                    </span>
                  </Link>
                  <Link to="#">
                    <span className="badge badge-pill badge-design">
                      Illustrator
                    </span>
                  </Link>
                  <Link to="#">
                    <span className="badge badge-pill badge-design">HTML</span>
                  </Link>
                  <Link to="#">
                    <span className="badge badge-pill badge-design">
                      Whiteboard
                    </span>
                  </Link>
                </div>
              </div> */}
              <div className="company-detail-block">
                <h4 className="company-detail-title">{t('postDetails.images')}</h4>
                <div className="row row-gap">
                  <Gallery>
                    {post?.images?.map((item, index) => (
                      <Item
                        key={index}
                        original={item}
                        thumbnail={item}
                        width="1024"
                        height="768"
                      >
                        {({ ref, open }) => (
                          <img
                            ref={ref}
                            onClick={open}
                            src={item}
                            alt={`Image ${index + 1}`}
                            style={{
                              cursor: "pointer",
                              width: 200,
                              height: 200,
                            }}
                          />
                        )}
                      </Item>
                    ))}
                  </Gallery>

                  {/* <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="attachment-file">
                    <img src={Project_21} alt="" width={50}/>
                      <div className="attachment-files-details">
                        <h6>sample.jpg</h6>
                        <span>file size 139 KB</span>
                      </div>
                      <Link to="#" className="file-download-btn">
                        <i className="feather-trash" />
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="attachment-file">
                      <img src={Project_21} alt="" width={50}/>
                      <div className="attachment-files-details">
                        <h6>Website logo.jpg</h6>
                        <span>file size 139 KB</span>
                      </div>
                      <Link to="#" className="file-download-btn">
                        <i className="feather-trash" />
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="attachment-file">
                    <img src={Project_21} alt="" width={50}/>
                      <div className="attachment-files-details">
                        <h6>Banner image.jpg</h6>
                        <span>file size 139 KB</span>
                      </div>
                      <Link to="#" className="file-download-btn">
                        <i className="feather-trash" />
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">

                    <div className="attachment-file">
                    <img src={Project_21} alt="" width={50}/>
                      <div className="attachment-files-details">
                        <h6>Services image.jpg</h6>
                        <span>file size 139 KB</span>
                      </div>
                      <Link to="#" className="file-download-btn">
                        <i className="feather-trash" />
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">

                    <div className="attachment-file">
                    <img src={Project_21} alt="" width={50}/>
                      <div className="attachment-files-details">
                        <h6>About us.jpg</h6>
                        <span>file size 139 KB</span>
                      </div>
                      <Link to="#" className="file-download-btn">
                        <i className="feather-trash" />
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="attachment-file">
                    <img src={Project_21} alt="" width={50}/>
                      <div className="attachment-files-details">
                        <h6>Website Content.png</h6>
                        <span>file size 139 KB</span>
                      </div>
                      <Link to="#" className="file-download-btn">
                        <i className="feather-trash" />
                      </Link>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="company-detail-block pb-0">
                <h4 className="company-detail-title">
                {t('postDetails.comments')} ({post?.comments?.length})
                </h4>
                {claims.sub !== user?.id && (
                  <>
                    <h5>{t('postDetails.writeComment')}</h5>
                    <p>{t('postDetails.commentNotice')}</p>
                    <textarea
                      rows={5}
                      className="form-control mb-3"
                      placeholder={t('postDetails.writeComment')}
                      value={comment}
                      onChange={(e) => setComment(e.currentTarget.value)}
                    />
                    <button
                      type="button"
                      className="btn  btn-primary mb-3"
                      style={{ width: "100%", padding: "10px" }}
                      onClick={handleAddComment}
                    >
                      {t('postDetails.submitProposal')}
                    </button>
                  </>
                )}

                {post?.comments?.map((comment, ind) => {
                  const userComment = userCommentCache[comment.userId] || {};

                  return (
                    <>
                      <div
                        className="project-proposals-block "
                        key={comment.id}
                      >
                        <div className="project-proposals-img">
                          <Link to={`/company-details/${userComment?.id}`}>
                            <img
                              src={userComment?.image || Avatar_1}
                              className="img-fluid"
                              alt="user"
                            />
                          </Link>
                        </div>
                        <div className="project-proposals-description">
                          <div className="proposals-user-detail">
                            <div>
                              <h5>
                                <Link
                                  to={`/company-details/${userComment?.id}`}
                                >
                                  {userComment?.businessName}
                                </Link>
                              </h5>
                              <ul className="d-flex">
                                {/* <li>
            <div className="proposals-user-review">
              <span>
                <i className="fa fa-star" />
                5.0 (346 Reviews)
              </span>
            </div>
          </li> */}
                                <li>
                                  <div className="proposals-user-review">
                                    <span>
                                      <i className="feather-calendar" />
                                      {getDateTime.getDate(
                                        comment.createdAt
                                      )} |{" "}
                                      {getDateTime.getTime(comment.createdAt)}
                                    </span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div>
                              <div className="proposals-pricing">
                                {/* <h4>$40-$500</h4> */}
                                {/* <span>Price : Fixed </span> */}
                              </div>
                            </div>
                          </div>
                          <p
                            className="mb-0"
                            style={{ width: "620px", wordBreak: "break-all" }}
                          >
                            {comment?.text}
                          </p>
                        </div>
                        {comment.userId === claims.sub && (
                          <a
                            href="#"
                            style={{ margin: "0 auto" }}
                            onClick={() => handleDeleteComment(comment.id)}
                          >
                            <i className="fas fa-trash"></i>
                          </a>
                        )}
                        {(claims.sub === user?.id && claims.sub !==comment.userId)&& (
                          <button
                            style={{
                              margin: "0 auto",
                              display: "flex",
                              alignItems: "center",
                            }}
                            onClick={() => setUserToReply(comment.userId)}
                            type="button"
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#file"
                          >
                            Reply{" "}
                            <FontAwesomeIcon
                              icon={faComment}
                              style={{ margin: "0 4px" }}
                            />
                          </button>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            {/* Blog Sidebar */}
            <div className="col-lg-4 col-md-12 sidebar-right theiaStickySidebar project-client-view">
              <StickyBox offsetTop={70} offsetBottom={20}>
                {/* <div className="card budget-widget">
                  <div className="budget-widget-details">
                    <h6>Budget</h6>
                    <h4>$125 - $180</h4>
                    <p className="mb-0">Hourly Rate</p>
                  </div>
                  <p>Write comment and let the owner contact you.</p>
                  <div>
                    <Link
                      data-bs-toggle="modal"
                      to="#file"
                      className="btn proposal-btn btn-primary"
                    >
                      Submit Proposal
                    </Link>
                  </div>
                </div> */}
                {claims.sub !== user?.id && (
                  <div className="card budget-widget">
                    <div className="budget-widget-details">
                      <h6>{t('postDetails.aboutClient')}</h6>
                      <div className="company-detail-image">
                        <img
                          src={user?.image || Avatar_1}
                          className="img-fluid"
                          alt="logo"
                        />
                      </div>
                      <h5>{user?.businessName}</h5>
                      {/* <span>Member Since Juli 31, 2024</span> */}
                      {/* <div className="rating mb-3">
                                    <i className="fas fa-star filled" />
                                    <i className="fas fa-star filled" />
                                    <i className="fas fa-star filled" />
                                    <i className="fas fa-star filled" />
                                    <i className="fas fa-star filled" />
                                    <span className="average-rating">5.0</span>
                                  </div> */}
                      {/* 
                                  <ul className="d-flex list-style mb-0 social-list">
                                    <li>
                                      <Link to="#" className="social-link-block">
                                        <i className="fa-brands fa-facebook-f" />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="#" className="social-link-block">
                                        <i className="fab fa-twitter" />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="#" className="social-link-block">
                                        <i className="fa-brands fa-linkedin-in" />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="#" className="social-link-block">
                                        <i className="fa-brands fa-instagram" />
                                      </Link>
                                    </li>
                                  </ul> */}

                      {/* <ul className="d-flex list-style mb-0 client-detail-list">
                                    <li>
                                      <span>Departments</span>
                                      <p className="mb-0">Designer</p>
                                    </li>
                                    <li>
                                      <span>Employees</span>
                                      <p className="mb-0">30-50</p>
                                    </li>
                                  </ul> */}
                    </div>
                    <div style={{display:'flex',gap:'5px'}}>
                      <Link
                        to={`/company-details/${user?.id}`}
                        className="btn  btn-primary price-btn btn-block"
                      >
                        {t('postDetails.viewProfile')}{" "}
                      </Link>
                      {/* <Link
                                                    data-bs-toggle="modal"
                            data-bs-target="#contactModal"
                        className="btn  btn-primary price-btn btn-block"
                      >
                        Contact Me{" "}
                      </Link> */}
                    </div>
                  </div>
                )}

                {/* <div className="card budget-widget">
                  <ul className="d-flex mb-0 list-style job-list-block">
                    <li>
                      <span>Posts</span>
                      <p className="mb-0">48</p>
                    </li>
                    <li>
                      <span>Requests</span>
                      <p className="mb-0">22</p>
                    </li>
                  
                  </ul>
                </div> */}
              </StickyBox>
            </div>
            {/* /Blog Sidebar */}
          </div>
        </div>
      </div>

      {/* The Modal */}

      <div
        className="modal fade"
        id="file"
        tabIndex="-1"
        aria-labelledby="fileLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between">
              <h4 className="modal-title" id="fileLabel">
              {t('postDetails.writeMessage')}
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form action="/freelancer-project-proposals">
                <div className="row">
                  <div className="col-md-12 input-block">
                    {/* <label className="form-label">Write Message</label> */}
                    {/* Assuming TextEditor is a custom component */}
                    {/* <TextEditor /> */}
                    <textarea
                      value={replyMessage}
                      onChange={(e) => setReplyMessage(e.target.value)}
                      rows={5}
                      className="form-control"
                      placeholder="Enter your message"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 submit-section">
                    {/* <label className="custom_check">
                      <input type="checkbox" name="select_time" />
                      <span className="checkmark" /> I agree to the Terms And Conditions
                    </label> */}
                  </div>
                  <div className="col-md-12 submit-section text-end">
                    <Link
                      data-bs-dismiss="modal"
                      className="btn btn-cancel submit-btn"
                    >
                      {t('postDetails.cancel')}
                    </Link>
                    <Link
                      onClick={handleSendReply}
                      className="btn btn-primary submit-btn"
                    >
                      {t('postDetails.send')}
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* /The Modal */}

      <div
        className="modal fade"
        id="contactModal"
        tabIndex="-1"
        aria-labelledby="fileLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between">
              <h4 className="modal-title" id="fileLabel">
              {t('writeMessage')}
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form action="/freelancer-project-proposals">
                <div className="row">
                  <div className="col-md-12 input-block">
                    {/* <label className="form-label">Write Message</label> */}
                    {/* Assuming TextEditor is a custom component */}
                    {/* <TextEditor /> */}
                    <textarea
                      value={contactMessage}
                      onChange={(e) => setContactMessage(e.target.value)}
                      rows={5}
                      className="form-control"
                      placeholder="Enter your message"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 submit-section">
                    {/* <label className="custom_check">
                      <input type="checkbox" name="select_time" />
                      <span className="checkmark" /> I agree to the Terms And Conditions
                    </label> */}
                  </div>
                  <div className="col-md-12 submit-section text-end">
                    <Link
                      data-bs-dismiss="modal"
                      className="btn btn-cancel submit-btn"
                    >
                      {t('cancel')}
                    </Link>
                    <Link
                      onClick={handleContactMe}
                      className="btn btn-primary submit-btn"
                    >
                      {t('send')}
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CompanyProfile;
