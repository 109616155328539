/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  about_details,
  Bg_13,
  home_icon,
  logo_details,
  profile_details,
  Tab_icon_09,
  Tab_icon_10,
  Tab_icon_11,
  logo,
  logo_icon_01,
  Flags_nl,
  find,
  requests,
  posts,
  Avatar_1,
} from "../imagepath";
import { useHistory, useParams } from "react-router-dom";
import TokenManager from "../../../Apis/TokenManager";
import LoadingSpinner from "../../../LoadingSpinner";
import AuthApi from "../../../Apis/AuthApi";
import { toast } from "react-toastify";
import TechOffers from "../../../Apis/TechOffersApi";
import TechRequestsApi from "../../../Apis/TechRequestsApi";
import StickyBox from "react-sticky-box";
import { ProfileSidebar } from "../forfreelancer/sidebar/profilesidebar";
import { useTranslation } from "react-i18next";

const CompanyDetails = () => {
  const { t } = useTranslation();
  const history = useHistory();
  let params = useParams();
  const claims = TokenManager.getClaims();

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [request, setRequest] = useState();

  const getUserById = async (id) => {
    const response = await AuthApi.getUser(id);
    if (response.success) {
      setUser(response.data);
      setLoading(false);
    } else {
      toast.error("something went wrong");
      setLoading(false);
    }
  };

  const getUserTechRequests = async (id) => {
    const response = await TechRequestsApi.getTechRequestByUserID(id);
    if (response.success) {
      setRequest(response.data);
    } else {
      toast.error("something went wrong");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const isValidUUID = (id) => {
        const uuidRegex =
          /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        return uuidRegex.test(id);
      };
      if (params?.id && isValidUUID(params?.id)) {
        getUserById(params?.id);
      } else {
        history.push("/404");
      }
    };
    fetchData();
  }, [params?.id]);


  if (loading) {
    return <LoadingSpinner />;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Breadcrumb */}
      <div className="bread-crumb-bar">
        <div className="container">
          <div className="row align-items-center inner-banner">
            <div className="col-md-12 col-12 text-center">
              <div className="breadcrumb-list">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">
                        <img src={home_icon} alt="" /> Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    {t('CompanyDetails.companyProfile')}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      {/* Profile Banner */}
      <section
        className="profile-baner"
        style={{
          background: `url(${Bg_13}) center center no-repeat`,
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-auto">
              <div className="profile-img">
                <img src={user?.image || Avatar_1} alt="" width={150} />
              </div> 
            </div>
            <div className="col">
              <div className="profile-main">
                <h2>
                  {user?.businessName} <i className="fas fa-check-circle" />
                </h2>
                {/* <p>Since July 2024</p> */}
                <div className="about-list">
                  <ul>
                    <li>
                      <img src={Flags_nl} alt="Lang" /> {user?.address?.country}
                    </li>
                    <li>
                      <i className="fas fa-map-marker-alt" /> {user?.address?.city}
                    </li>
                    <li>
                      <i className="far fa-clock" /> {t('CompanyDetails.postedOneHourAgo')}
                    </li>
                  </ul>
                </div>
                {/* <div className="rating">
                                    <i className="fas fa-star filled" />
                                    <i className="fas fa-star filled" />
                                    <i className="fas fa-star filled" />
                                    <i className="fas fa-star " />
                                    <i className="fas fa-star" />
                                    <span className="average-rating ml-2">4.6 (25)</span>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Profile Banner */}
      {/* Page Content */}
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="pro-view">
                {/* Tab Detail */}
                <nav className="provider-tabs abouts-view">
                  <ul className="nav nav-tabs nav-tabs-solid nav-justified">
                    <li className="nav-item">
                      <Link
                        className="nav-link active-about"
                        to={`/company-details/${user?.id}`}
                      >
                        <img src={find} alt="User Image" width={30} />
                        <p className="bg-red">{t('CompanyDetails.about')}</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to={`/company-posts/${user?.id}`}
                      >
                        <img src={posts} alt="User Image" width={30} />
                        <p>{t('CompanyDetails.posts')}</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to={`/company-requests/${user?.id}`}>
                        <img src={requests} alt="User Image" width={30} />
                        <p>{t('CompanyDetails.requests')}</p>
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                                            <Link className="nav-link" to="/company-review">
                                                <img src={Tab_icon_11} alt="User Image" />
                                                <p>Reviews</p>
                                            </Link>
                                        </li> */}
                  </ul>
                </nav>
                {/* /Tab Detail */}
                {/* About Tab Content */}
                <div className="pro-post widget-box company-post abouts-detail">
                  <h3 className="pro-title">{t('CompanyDetails.aboutUs')}</h3>
                  <div className="pro-content">
                    <p>{user?.description}</p>
                  </div>
                </div>
                {/* /About Tab Content */}
              </div>
            </div>
            {/* profile Sidebar */}
            <div className="col-lg-4 col-md-12 sidebar-right theiaStickySidebar company-profile">
              <StickyBox offsetTop={20} offsetBottom={20}>
                <ProfileSidebar user={user}/>
              </StickyBox>
            </div>
            {/* /Profile Sidebar */}
          </div>
        </div>
      </div>
      {/* /Page Content */}
      {/* The Modal */}
      {/* <div className="modal fade custom-modal" id="hire">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content bg-modal">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center pt-0 mb-4">

                <h5 className="modal-title">Discuss your project with David</h5>
              </div>
              <form action="#">
                <div className="modal-info">
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="First name & Lastname"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          name="name"
                          className="form-control"
                          placeholder="Email Address"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Phone Number"
                        />
                      </div>
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          name="message"
                          rows={5}
                          placeholder="Message"
                          defaultValue={""}
                        />
                      </div>
                      <div className="form-group row">
                        <div className="col-12 col-md-4 pr-0">
                          <label className="file-upload">
                            Add Attachment <input type="file" />
                          </label>
                        </div>
                        <div className="col-12 col-md-8">
                          <p className="mb-1">
                            Allowed file types: zip, pdf, png, jpg
                          </p>
                          <p>Max file size: 50 MB</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="submit-section text-center">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block submit-btn"
                  >
                    Hire Now
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
      {/* /The Modal */}
      {/* /Main Wrapper */}
    </>
  );
};
export default CompanyDetails;
