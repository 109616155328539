/* eslint-disable no-unused-vars */
import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom/client';
import AppRouter from './frontend/approuter';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import '../src/custom.css'

// Use For Switch
// import 'react-toggle-switch/dist/css/switch.min.css';

// createRoot(document.getElementById('root')).render(<AppRouter />);

// if (module.hot) {
//   // enables hot module replacement if plugin is installed
//   module.hot.accept();
// }
i18n
.use(initReactI18next) 
.use(LanguageDetector)
.use(HttpApi)
.init({
  supportedLngs:['en','nl','ar'],
  fallbackLng:'en',
  detection:{
      order: [  'path','cookie', 'htmlTag','localStorage', 'sessionStorage', 'subdomain'],
      caches:['cookie']
  },
  backend:{
      loadPath: '/locales/{{lng}}/translation.json',
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <AppRouter/>
  </>
);
