/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import StickyBox from "react-sticky-box";
import { home_icon } from "../../imagepath";
import { Sidebar } from "../sidebar";
import { Table } from "antd";
import { itemRender, onShowSizeChange } from "../../paginationfunction";
import "../../antdstyle.css";
import { useTranslation } from "react-i18next";
const FreelancerMembership = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.body.className = "dashboard-page";
    return () => {
      document.body.className = "";
    };
  });
  const data = [
    {
      id: 1,
      purchasedate: "15 October 2023",
      details: "The Unlimita Invoice : IVIP12023598",
      expirydate: "	15th July 2022",
      type: "Yearly",
      status: "Inactive",
      bg: "badge badge-danger-lite",
      price: "$200.00",
    },
    {
      id: 2,
      purchasedate: "15 October 2023",
      details: "The Unlimita Invoice : IVIP12023598",
      expirydate: "15th July 2023",
      type: "Yearly",
      status: "Active",
      bg: "badge badge-paid",
      price: "$200.00",
    },
    {
      id: 3,
      purchasedate: "15 October 2023",
      details: "The Unlimita Invoice : IVIP12023598",
      expirydate: "15th July 2023",
      type: "Yearly",
      status: "Active",
      bg: "badge badge-paid",
      price: "$200.00",
    },
  ];
  const columns = [
    {
      title: "Purchasedate",
      dataIndex: "purchasedate",
      render: (text, record) => <div>{text}</div>,
      sorter: (a, b) => a.purchasedate.length - b.purchasedate.length,
    },
    {
      title: "Details",
      dataIndex: "details",
      render: (text, record) => (
        <div>
          <p className="mb-0">{text}</p>
          <Link to="#" className="read-text">
            Invoice : IVIP12023598
          </Link>
        </div>
      ),
      sorter: (a, b) => a.details.length - b.details.length,
    },
    {
      title: "Expiry Date",
      dataIndex: "expirydate",
      render: (text, record) => <div>{text}</div>,
      sorter: (a, b) => a.expirydate.length - b.expirydate.length,
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (text, record) => <div>{text}</div>,
      sorter: (a, b) => a.type.length - b.type.length,
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (text, record) => <div>{text}</div>,
      sorter: (a, b) => a.price.length - b.price.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => <span className={record.bg}>{text}</span>,
      sorter: (a, b) => a.status.length - b.status.length,
    },
  ];
  return (
    <>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-md-4 theiaStickySidebar">
              <StickyBox offsetTop={20} offsetBottom={20}>
                <Sidebar />
              </StickyBox>
            </div>
            <div className="col-xl-9 col-lg-8">
              <div className="dashboard-sec payout-section freelancer-statements plan-billing">
                <div className="page-title portfolio-title">
                  <h3 className="mb-0">{t('membership.title')}</h3>
                </div>
                <div className="plan-billing-section">
                  <div className="row row-gap">
                    <div className="col-xl-4 col-md-6">
                      <div className="package-detail">
                      <h4>{t('membership.freePlan')}</h4>
                      <p>{t('membership.freePlanDescription')}</p>
                        <h3 className="package-price">
                        {t('membership.freePlanPrice')}
                        </h3>
                        <div className="package-feature">
                          <ul>
                          {t('membership.freePlanFeatures', { returnObjects: true }).map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
                          </ul>
                        </div>
                        <Link
                          to="#payout_modal"
                          data-bs-toggle="modal"
                          className="btn btn-outline-primary btn-block"
                        >
                         {t('membership.selectPlan')}
                        </Link>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                      <div className="package-detail">
                        <h4>{t('membership.businessPlan')} </h4>
                        <p>{t('membership.businessPlanDescription')}</p>
                        <h3 className="package-price">
                        {t('membership.businessPlanPrice')}
                        </h3>
                        <div className="package-feature">
                        <ul>
        {t('membership.businessPlanFeatures', { returnObjects: true }).map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
                        </div>
                        <Link
                          to="#payout_modal"
                          data-bs-toggle="modal"
                          className="btn btn-outline-primary btn-block"
                        >
                          {t('membership.selectPlan')}
                        </Link>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                      <div className="package-detail">
                        <h4>{t('membership.unlimitedPlan')}</h4>
                        <p>{t('membership.unlimitedPlanDescription')}</p>
                        <h3 className="package-price">
                        {t('membership.unlimitedPlanPrice')}
                        </h3>
                        <div className="package-feature">
                        <ul>
        {t('membership.unlimitedPlanFeatures', { returnObjects: true }).map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
                        </div>
                        <Link
                          to="#payout_modal"
                          data-bs-toggle="modal"
                          className="btn btn-outline-primary btn-block"
                        >
                          {t('membership.selectPlan')}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="page-title ">
                  <h3>{t('membership.currentPlan')}</h3>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="member-plan pro-box">
                      <div className="member-detail">
                        <div className="row">
                          <div className="col-md-4">
                            <h5>{t('membership.currentPlanName')}</h5>
                            <div className="yr-amt">
                            {t('membership.currentPlanDescription')}
                            </div>
                            <div className="expiry-on">
                              <span>
                                <i className="feather-calendar" />
                                {t('membership.renewDate')}
                              </span>{" "}
                              {t('membership.renewDateValue')}
                            </div>
                          </div>
                          <div className="col-md-8 change-plan mt-3 mt-md-0">
                            <div>
                            <h3>{t('membership.freePlanPrice')}</h3>
                              <div className="yr-duration">
                              {t('membership.duration')}
                              </div>
                            </div>
                            <div className="change-plan-btn">
                              <Link
                                to="javascript:void(0);"
                                className="btn  btn-primary-lite"
                              >
                                {t('membership.cancelSubscription')}
                              </Link>
                              <Link
                                to="javascript:void(0);"
                                className="btn btn-primary black-btn"
                              >
                                {t('membership.changePlan')}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Table */}
                {/* <div className="table-top-section">
                  <div className="table-header">
                    <h5 className="mb-0">Statement</h5>
                  </div>
                </div>
                <div className="table-responsive">
                  <Table
                    pagination={{
                      total: data.length,
                      showTotal: (total, range) =>
                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                      showSizeChanger: true,
                      onShowSizeChange: onShowSizeChange,
                      itemRender: itemRender,
                    }}
                    className="table role"
                    style={{ overflowX: "auto" }}
                    columns={columns}
                    dataSource={data}
                    rowKey={(record) => record.id}
                  />
                </div> */}
                {/* /Table */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FreelancerMembership;
