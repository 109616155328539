/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useCategory } from "../../Category";
import { useSubCategory } from "../../SubCategoryContext";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FreelancerSidebar = ({ allposts, setAllPosts,stateWord }) => {
  const location=useLocation() 
  const testWord = location ;
  const [loading, setLaoding] = useState(false);
  const { categories, getCategoryById,fetchCategories } = useCategory();
  const { subCategories, fetchSubCategories } = useSubCategory();
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [allSubCategory, setAllSubCategory] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const { t } = useTranslation();

  const handleTermChange = () => {

    // If no search term and no subcategories are selected, reset to all posts
    if (searchTerm === "" && selectedSubCategories?.length === 0) {
      setAllPosts(allposts);
      return;
    }

    const search = searchTerm?.toLowerCase();

    const selectedPosts = allposts?.filter((post) => {
      const cat = getCategoryById(post.categoryId)?.name?.toLowerCase();
      const desc = post.description?.toLowerCase();
      const title = post.title?.toLowerCase();

      // Filter by selected subcategories
      const matchesSubCategory =
        selectedSubCategories.length === 0 ||
        selectedSubCategories.some(
          (subCat) => subCat.id === post.subCategoryId
        );

      // Filter by search term
      const matchesSearchTerm =
        search === "" ||
        cat?.includes(search) ||
        desc?.includes(search) ||
        title?.includes(search);

      return matchesSubCategory && matchesSearchTerm;
    });
    setAllPosts(selectedPosts);
    console.log("selectedPosts", selectedPosts);
  };

  const handleCheckboxChange = (subCategory) => {
    setSelectedSubCategories((prevSelected) => {
      // Check if the subcategory is already selected
      const isSelected = prevSelected.some(
        (item) => item.id === subCategory.id
      );

      // If it's selected, remove it from the array; otherwise, add it
      if (isSelected) {
        return prevSelected.filter((item) => item.id !== subCategory.id);
      } else {
        return [...prevSelected, subCategory];
      }
    });
  };

  useEffect(() => {
    if (allposts?.length > 0) {
      handleTermChange();
    }
  }, [searchTerm, selectedSubCategories]);

  useEffect(() => {
    const getSubCategories = async () => {
      try {
        const subCategoPromises = categories.map(async (cat) => {
          const subCat = await fetchSubCategories(cat.id);
          return subCat;
        });

        const subCatego = await Promise.all(subCategoPromises);
        setAllSubCategory(subCatego.flat()); // Flatten the array if needed
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };

    if (categories.length > 0) {
      getSubCategories();
      setLaoding(false)
    }else{
      setLaoding(true)
    }
  }, [categories]);

  useEffect(()=>{
    fetchCategories()
  },[])

  useEffect(()=>{
    setSearchTerm(stateWord)
    handleTermChange();
  },[stateWord])
  
  useEffect(()=>{
    setSearchTerm(testWord?.state?.state?.searchWord)
    handleTermChange();
   },[])

   
  return (
    <div className="card search-filter">
      <div className="card-header d-flex justify-content-between">
        <h4 className="card-title mb-0">{t('search.searchFilter')}</h4>
      </div>
      {loading ? (
      <p>...Loading</p>  
      ) : (
        <>
          <div className="card-body">
            <div>
              <input
              style={{border:"1px solid red"}}
                type="text"
                className="form-control mb-3"
                placeholder={t('search.placeholder')} 
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="filter-widget">
              <h4 className="filter-title">
                <Link
                  to="#"
                  className="w-100"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapselocation"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  {categories[0]?.name}
                  <span className="float-end">
                    <i className="fa-solid fa-chevron-down" />
                  </span>
                </Link>
              </h4>

              <div
                id="collapselocation"
                className="collapse show"
                data-bs-parent="#accordionExample1"
              >
                {allSubCategory?.map((subCat) => {
                  if (subCat.categoryId === categories[0]?.id) {
                    return (
                      <div key={subCat.id}>
                        <label className="custom_check">
                          <input
                            type="checkbox"
                            name={subCat.name}
                            checked={selectedSubCategories.some(
                              (item) => item.id === subCat.id
                            )}
                            onChange={() => handleCheckboxChange(subCat)}
                          />
                          <span className="checkmark" />
                          {subCat.name}
                        </label>
                      </div>
                    );
                  }
                  return null; // Return null if the condition is not met to avoid undefined elements in the DOM
                })}

                {/* <div
              id="collapseOnes3"
              className="collapse"
              data-bs-parent="#accordionExample1"
            >
              <div>
                <label className="custom_check">
                  <input type="checkbox" name="select_time" />
                  <span className="checkmark" /> Hard Drives
                </label>
              </div>
              <div>
                <label className="custom_check">
                  <input type="checkbox" name="select_time" />
                  <span className="checkmark" />
                  Memory (RAM)
                </label>
              </div>
              <div>
                <label className="custom_check">
                  <input type="checkbox" name="select_time" />
                  <span className="checkmark" />
                  Power Adapters
                </label>
              </div>
              <div>
                <label className="custom_check">
                  <input type="checkbox" name="select_time" />
                  <span className="checkmark" />
                  Cooling Fans
                </label>
              </div>
              <div>
                <label className="custom_check">
                  <input type="checkbox" name="select_time" />
                  <span className="checkmark" />
                  Hinges
                </label>
              </div>
              <div>
                <label className="custom_check">
                  <input type="checkbox" name="select_time" />
                  <span className="checkmark" />
                  Casing
                </label>
              </div>
              <div>
                <label className="custom_check">
                  <input type="checkbox" name="select_time" />
                  <span className="checkmark" />
                  Trackpads
                </label>
              </div>
              <div>
                <label className="custom_check">
                  <input type="checkbox" name="select_time" />
                  <span className="checkmark" />
                  Speakers
                </label>
              </div>
              <div>
                <label className="custom_check">
                  <input type="checkbox" name="select_time" />
                  <span className="checkmark" />
                  Ports & Connectors
                </label>
              </div>
              <div>
                <label className="custom_check">
                  <input type="checkbox" name="select_time" />
                  <span className="checkmark" />
                  All
                </label>
              </div>
            </div>

            <div className="showmore mt-2">
              <Link
                to="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOnes3"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <i className="feather-plus me-1" />
                Show More
              </Link>
            </div> */}
              </div>
            </div>
            <div className="filter-widget">
              <h4 className="filter-title">
                <Link
                  to="#"
                  className="w-100"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseskills"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  {categories[1]?.name}
                  <span className="float-end">
                    <i className="fa-solid fa-chevron-down" />
                  </span>
                </Link>
              </h4>
              <div
                id="collapseskills"
                className="collapse show"
                data-bs-parent="#accordionExample1"
              >
                {allSubCategory?.map((subCat) => {
                  if (subCat.categoryId === categories[1]?.id) {
                    return (
                      <div key={subCat.id}>
                        <label className="custom_check">
                          <input
                            type="checkbox"
                            name={subCat.name}
                            checked={selectedSubCategories.some(
                              (item) => item.id === subCat.id
                            )}
                            onChange={() => handleCheckboxChange(subCat)}
                          />
                          <span className="checkmark" />
                          {subCat.name}
                        </label>
                      </div>
                    );
                  }
                  return null; // Return null if the condition is not met to avoid undefined elements in the DOM
                })}

                {/* <div>
              <label className="custom_check">
                <input type="checkbox" name="select_time" />
                <span className="checkmark" /> Screens
              </label>
            </div>

            <div>
              <label className="custom_check">
                <input type="checkbox" name="select_time" />
                <span className="checkmark" /> Batteries
              </label>
            </div>

            <div>
              <label className="custom_check">
                <input type="checkbox" name="select_time" />
                <span className="checkmark" /> Cameras
              </label>
            </div>

            <div>
              <label className="custom_check">
                <input type="checkbox" name="select_time" />
                <span className="checkmark" /> Motherboards
              </label>
            </div>

            <div
              id="collapseOnes1"
              className="collapse"
              data-bs-parent="#accordionExample1"
            >
              <div>
                <label className="custom_check">
                  <input type="checkbox" name="select_time" />
                  <span className="checkmark" /> Buttons
                </label>
              </div>
              <div>
                <label className="custom_check">
                  <input type="checkbox" name="select_time" />
                  <span className="checkmark" /> Casing
                </label>
              </div>
              <div>
                <label className="custom_check">
                  <input type="checkbox" name="select_time" />
                  <span className="checkmark" /> Charging Ports
                </label>
              </div>
              <div>
                <label className="custom_check">
                  <input type="checkbox" name="select_time" />
                  <span className="checkmark" /> Speakers
                </label>
              </div>
              <div>
                <label className="custom_check">
                  <input type="checkbox" name="select_time" />
                  <span className="checkmark" /> Microphones
                </label>
              </div>
              <div>
                <label className="custom_check">
                  <input type="checkbox" name="select_time" />
                  <span className="checkmark" /> Speakers
                </label>
              </div>
              <div>
                <label className="custom_check">
                  <input type="checkbox" name="select_time" />
                  <span className="checkmark" /> SIM Trays
                </label>
              </div>
              <div>
                <label className="custom_check">
                  <input type="checkbox" name="select_time" />
                  <span className="checkmark" /> Antennas
                </label>
              </div>
              <div>
                <label className="custom_check">
                  <input type="checkbox" name="select_time" />
                  <span className="checkmark" /> Sensors
                </label>
              </div>
              <div>
                <label className="custom_check">
                  <input type="checkbox" name="select_time" />
                  <span className="checkmark" /> All
                </label>
              </div>
            </div>

            <div className="showmore mt-2">
              <Link
                to="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOnes1"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <i className="feather-plus me-1" />
                Show More
              </Link>
            </div> */}
              </div>
            </div>
            <div className="filter-widget">
              <h4 className="filter-title">
                <Link
                  to="#"
                  className="w-100"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  {categories[2]?.name}
                  <span className="float-end">
                    <i className="fa-solid fa-chevron-down" />
                  </span>
                </Link>
              </h4>
              <div
                id="collapseOne"
                className="collapse show"
                data-bs-parent="#accordionExample1"
              >
                {allSubCategory?.map((subCat) => {
                  if (subCat.categoryId === categories[2]?.id) {
                    return (
                      <div key={subCat.id}>
                        <label className="custom_check">
                          <input
                            type="checkbox"
                            name={subCat.name}
                            checked={selectedSubCategories.some(
                              (item) => item.id === subCat.id
                            )}
                            onChange={() => handleCheckboxChange(subCat)}
                          />
                          <span className="checkmark" />
                          {subCat.name}
                        </label>
                      </div>
                    );
                  }
                  return null; // Return null if the condition is not met to avoid undefined elements in the DOM
                })}

                {/* <div>
              <label className="custom_check">
                <input type="checkbox" name="select_time" />
                <span className="checkmark" /> Monitors
              </label>
            </div>

            <div>
              <label className="custom_check">
                <input type="checkbox" name="select_time" />
                <span className="checkmark" /> CPUs
              </label>
            </div>

            <div>
              <label className="custom_check">
                <input type="checkbox" name="select_time" />
                <span className="checkmark" /> Motherboards
              </label>
            </div>

            <div>
              <label className="custom_check">
                <input type="checkbox" name="select_time" />
                <span className="checkmark" /> Graphics Cards
              </label>
            </div>

            <div
              id="collapseOnes"
              className="collapse"
              data-bs-parent="#accordionExample1"
            >
              <div>
                <label className="custom_check">
                  <input type="checkbox" name="select_time" />
                  <span className="checkmark" /> Power Supplies
                </label>
              </div>
              <div>
                <label className="custom_check">
                  <input type="checkbox" name="select_time" />
                  <span className="checkmark" /> Cooling Systems
                </label>
              </div>
              <div>
                <label className="custom_check">
                  <input type="checkbox" name="select_time" />
                  <span className="checkmark" /> All
                </label>
              </div>
            </div>

            <div className="showmore mt-2">
              <Link
                to="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOnes"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <i className="feather-plus me-1" />
                Show More
              </Link>
            </div> */}
              </div>
            </div>
            <div className="filter-widget">
              <h4 className="filter-title">
                <Link
                  to="#"
                  className="w-100"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsproject"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  {categories[3]?.name}
                  <span className="float-end">
                    <i className="fa-solid fa-chevron-down" />
                  </span>
                </Link>
              </h4>
              <div
                id="collapsproject"
                className="collapse show"
                data-bs-parent="#accordionExample1"
              >
                {/* <div>
              <label className="custom_check">
                <input type="checkbox" name="select_time" />
                <span className="checkmark" />
                Screwdrivers
              </label>
            </div>
            <div>
              <label className="custom_check">
                <input type="checkbox" name="select_time" />
                <span className="checkmark" />
                Pry Tools
              </label>
            </div>
            <div>
              <label className="custom_check">
                <input type="checkbox" name="select_time" />
                <span className="checkmark" />
                Adhesive
              </label>
            </div>
            <div>
              <label className="custom_check">
                <input type="checkbox" name="select_time" />
                <span className="checkmark" />
                Heat Guns
              </label>
            </div>
            <div>
              <label className="custom_check">
                <input type="checkbox" name="select_time" />
                <span className="checkmark" />
                Multimeters
              </label>
            </div>
            <div>
              <label className="custom_check">
                <input type="checkbox" name="select_time" />
                <span className="checkmark" />
                Soldering Equipment
              </label>
            </div> */}

                {allSubCategory?.map((subCat) => {
                  if (subCat.categoryId === categories[3]?.id) {
                    return (
                      <div key={subCat.id}>
                        <label className="custom_check">
                          <input
                            type="checkbox"
                            name={subCat.name}
                            checked={selectedSubCategories.some(
                              (item) => item.id === subCat.id
                            )}
                            onChange={() => handleCheckboxChange(subCat)}
                          />
                          <span className="checkmark" />
                          {subCat.name}
                        </label>
                      </div>
                    );
                  }
                  return null; // Return null if the condition is not met to avoid undefined elements in the DOM
                })}
              </div>
            </div>
            <div className="filter-widget">
              <h4 className="filter-title">
                <Link
                  to="#"
                  className="w-100"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapselanguage"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  {categories[4]?.name}
                  <span className="float-end">
                    <i className="fa-solid fa-chevron-down" />
                  </span>
                </Link>
              </h4>
              <div
                id="collapselanguage"
                className="collapse show"
                data-bs-parent="#accordionExample1"
              >
                {allSubCategory?.map((subCat) => {
                  if (subCat.categoryId === categories[4]?.id) {
                    return (
                      <div key={subCat.id}>
                        <label className="custom_check">
                          <input
                            type="checkbox"
                            name={subCat.name}
                            checked={selectedSubCategories.some(
                              (item) => item.id === subCat.id
                            )}
                            onChange={() => handleCheckboxChange(subCat)}
                          />
                          <span className="checkmark" />
                          {subCat.name}
                        </label>
                      </div>
                    );
                  }
                  return null; // Return null if the condition is not met to avoid undefined elements in the DOM
                })}

                {/* <div>
              <label className="custom_check">
                <input type="checkbox" name="select_time" />
                <span className="checkmark" /> Screen Repair Kits
              </label>
            </div>
            <div>
              <label className="custom_check">
                <input type="checkbox" name="select_time" />
                <span className="checkmark" /> Battery Replacement Kits
              </label>
            </div>
            <div>
              <label className="custom_check">
                <input type="checkbox" name="select_time" />
                <span className="checkmark" />
                Camera Repair Kits
              </label>
            </div> */}
              </div>
            </div>
            <div className="filter-widget">
              <h4 className="filter-title">
                <Link
                  to="#"
                  className="w-100"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapselanguagea"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  {categories[5]?.name}
                  <span className="float-end">
                    <i className="fa-solid fa-chevron-down" />
                  </span>
                </Link>
              </h4>
              <div
                id="collapselanguagea"
                className="collapse show"
                data-bs-parent="#accordionExample1"
              >
                {allSubCategory?.map((subCat) => {
                  if (subCat.categoryId === categories[5]?.id) {
                    return (
                      <div key={subCat.id}>
                        <label className="custom_check">
                          <input
                            type="checkbox"
                            name={subCat.name}
                            checked={selectedSubCategories.some(
                              (item) => item.id === subCat.id
                            )}
                            onChange={() => handleCheckboxChange(subCat)}
                          />
                          <span className="checkmark" />
                          {subCat.name}
                        </label>
                      </div>
                    );
                  }
                  return null; // Return null if the condition is not met to avoid undefined elements in the DOM
                })}

                {/* <div>
              <label className="custom_check">
                <input type="checkbox" name="select_time" />
                <span className="checkmark" />
                Diagnostic Tools
              </label>
            </div>
            <div>
              <label className="custom_check">
                <input type="checkbox" name="select_time" />
                <span className="checkmark" /> Firmware Updates
              </label>
            </div>
            <div>
              <label className="custom_check">
                <input type="checkbox" name="select_time" />
                <span className="checkmark" />
                Operating Systems
              </label>
            </div> */}
              </div>
            </div>
            {/* <div className="btn-search">
          <button type="button" className="btn btn-primary">
            Search
          </button>
          <button type="button" className="btn btn-block">
            Reset
          </button>
        </div> */}
          </div>
        </>
      )}
    </div>
  );
};

export default FreelancerSidebar;
