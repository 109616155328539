import React from "react";
import { Link } from "react-router-dom";
import { default as logo } from "../../assets/img/Mokoto.png"; 
import { default as  bundukIcon } from "../../assets/img/bundukIcon.png"; 
import { useTranslation } from "react-i18next";
// eslint-disable-next-line no-unused-vars

const Footer = (props) => {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars, react/prop-types
  const pathname = props.location.pathname.split("/")[1];

  const exclusionArray = [
    "/index-two",
    "/index-three",
    "/index-four",
    "/index-five",
    "/onboard-screen",
    "/onboard-screen-employer",
  ];
  // eslint-disable-next-line react/prop-types
  if (exclusionArray.indexOf(props.location.pathname) >= 0) {
    return "";
  }
  // console.log(props.location,"location")

  return (
    <>
      {/* Footer */}
      <footer className="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className=" col-lg-4 col-md-12">
                <div className="footer-bottom-logo">
                  <Link to="/" className="menu-logo">
                    <img src={logo} width={200} className="img-fluid" alt="Logo" />
                  </Link>
                  <p>
                  {t('footer.platformDescription')}
                  </p>
                  <ul>
                    <li>
                      <Link to="#">
                        <i
                          className="fa-brands fa-facebook-f"
                          aria-hidden="true"
                        />
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="#">
                        <i
                          className="fa-brands fa-twitter"
                          aria-hidden="true"
                        />
                      </Link>
                    </li> */}
                    <li>
                      <Link to="#">
                        <i
                          className="fa-brands fa-instagram"
                          aria-hidden="true"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i
                          className="fa-brands fa-linkedin"
                          aria-hidden="true"
                        />
                      </Link>
                    </li>
                  </ul>
                  <Link to="#" className="btn btn-connectus">
                  {t('footer.contactUs')}
                  </Link>
                </div>
              </div>
              <div className=" col-lg-8 col-md-12">
                <div className="row">
                  <div className="col-xl-3 col-md-6">
                    <div className="footer-widget footer-menu">
                      <h2 className="footer-title">{t('footer.usefulLinks')}</h2>
                      <ul>
                        {/* <li>
                          <Link to="/about">
                            <i className="fas fa-angle-right me-1" />
                            About Us
                          </Link>
                        </li> */}
                        {/* <li>
                          <Link to="/blog-list">
                            <i className="fas fa-angle-right me-1" />
                            Blog
                          </Link>
                        </li> */}
                        <li>
                          <Link to="/login">
                            <i className="fas fa-angle-right me-1" />
                            {t('footer.login')}
                          </Link>
                        </li>
                        <li>
                          <Link to="/register">
                            <i className="fas fa-angle-right me-1" />
                            {t('footer.register')}
                          </Link>
                        </li>
                        <li>
                          <Link to="/forgot-password">
                            <i className="fas fa-angle-right me-1" />
                            {t('footer.forgotPassword')}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className="col-xl-3 col-md-6">
                    <div className="footer-widget footer-menu">
                      <h2 className="footer-title">Help &amp; Support</h2>
                      <ul>
                        <li>
                          <Link to="#">
                            <i className="fas fa-angle-right me-1" />
                            Browse Candidates
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fas fa-angle-right me-1" />
                            Employers Dashboard
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fas fa-angle-right me-1" />
                            Job Packages
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fas fa-angle-right me-1" />
                            Jobs Featured
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fas fa-angle-right me-1" />
                            Post A Job
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                  <div className="col-xl-3 col-md-6">
                    <div className="footer-widget footer-menu">
                      <h2 className="footer-title">{t('footer.otherLinks')}</h2>
                      <ul>
                        {/* <li>
                          <Link to="/freelancer-dashboard">
                            <i className="fas fa-angle-right me-1" />
                            Freelancers
                          </Link>
                        </li>
                        <li>
                          <Link to="/freelancer-portfolio">
                            <i className="fas fa-angle-right me-1" />
                            Freelancer Details
                          </Link>
                        </li> */}
                        <li>
                          <Link to="/project">
                            <i className="fas fa-angle-right me-1" />
                            {t('footer.posts')}
                          </Link>
                        </li>
                        <li>
                          <Link to="/post-details">
                            <i className="fas fa-angle-right me-1" />
                            {t('footer.requestPart')}
                          </Link>
                        </li>
                        <li>
                          <Link to="/post-project">
                            <i className="fas fa-angle-right me-1" />
                            {t('footer.sharePost')}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6">
                    <div className="footer-widget footer-menu">
                      <h2 className="footer-title">{t('footer.connectWithUs')}</h2>
                      <ul>
                        {/* <li>
                          <Link to="/freelancer-chats">
                            <i className="fas fa-angle-right me-1" />
                            Chat
                          </Link>
                        </li> */}
                        <li>
                          <Link to="/faq">
                            <i className="fas fa-angle-right me-1" />
                            {t('footer.faq')}
                          </Link>
                        </li>
                        {/* <li>
                          <Link to="/freelancer-review">
                            <i className="fas fa-angle-right me-1" />
                            Reviews
                          </Link>
                        </li> */}
                        <li>
                          <Link to="/privacy-policy">
                            <i className="fas fa-angle-right me-1" />
                            {t('footer.privacyPolicy')}
                          </Link>
                        </li>
                        <li>
                          <Link to="/term-condition">
                            <i className="fas fa-angle-right me-1" />
                            {t('footer.termsOfUse')}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Footer Top */}
        {/* Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            {/* Copyright */}
            <div className="copyright">
              <div className="row">
                <div className="col-md-12">
                  <div className="copyright-text text-center">
                    <p className="mb-0">
                      Copyright 2024 © <a href="https://www.bunduk.com/" ><img src={bundukIcon} alt="" width={30}/> Bunduk</a>. All right reserved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* /Copyright */}
          </div>
        </div>
        {/* /Footer Bottom */}
      </footer>

      {/* /Footer */}
    </>
  );
};

export default Footer;
